import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const tech = () => (
  <Layout>
    <SEO
      title="Termékeink"
      description="Egyénre szabott optika Zugló szívében"
    />

    <div className="sitewrap xl:max-w-2xl   m-auto ">
      <div className="flex  justify-around flex-wrap mt-20">
      <Link to="/termekeink/szemuvegkeretek" className=" w-full md:w-1/4  custom-h2  choose text-left flex-col flex jus justify-around  mt-10 ">


            <h4 className="maintext1 mt-4 m-2"> szemüvegkeretek </h4>

        </Link>
        <Link to="/termekeink/szemuveglencsek" className=" w-full md:w-1/4  custom-h2  choose text-left flex-col flex jus justify-around  mt-10 ">


            <h4 className="maintext1 mt-4 m-2"> szemüveglencsék </h4>
          </Link>
        <Link to="/termekeink/kontaktlencsek" className=" w-full md:w-1/4  custom-h2  choose text-left flex-col flex jus justify-around  mt-10 ">


            <h4 className="maintext1 mt-4 m-2"> kontaktlencsék </h4>
          </Link>
      </div>
      <div className="flex  justify-around flex-wrap mt-10">
      <Link to="/termekeink/gyerekeknek" className="w-full md:w-1/4  custom-h2  choose text-left flex-col flex jus justify-around mb-10  ">


            <h4 className="maintext1 mt-4 m-2"> gyerekeknek </h4>
          </Link>
        <Link to="/termekeink/napszemuvegek" className=" w-full md:w-1/4  custom-h2  choose text-left flex-col flex jus justify-around mb-10  ">


            <h4 className="maintext1 mt-4 m-2"> napszemüvegek </h4>
          </Link>
      </div>
      <div className="flex  justify-around flex-wrap">
      <Link to="/termekeink/kiegeszitok" className=" w-full md:w-1/4  custom-h2  choose text-left flex-col flex jus justify-around mb-10 ">


            <h4 className="maintext1 mt-4 m-2"> kiegésztítők </h4>
          </Link>
      </div>
    </div>
  </Layout>
);

export default tech;
